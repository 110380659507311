import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout/layout'
import SEO from '../components/seo'

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'

import fourOhFourImage from '../images/404/f0ccce9d-711f-42e2-a861-9265c67f7811_rw_1200.gif'


const NotFoundPage = (props) => (
  <Layout location={props.location.pathname}>
    <SEO title="404: Not found" />
    <Container fluid className="mt-3">
      <Row className="d-flex">
        <Col>
          <Alert
            className="w-100"
            variant="warning"
          >
            BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body className="cardBodyBackground">
              <h1>Page Not Found</h1>
              <Row className="justify-content-center justify-content-md-end" style={{ marginBottom: '4rem' }}>
                <Col className="order-2 order-md-1 pr-md-4">
                  <h2>Oh deer, we had an accident.</h2>
                  <p className="lead">The page you were looking for was moved or does not exist.</p>
                  <p className="lead">Let's get you back on track:</p>
                  <Link to="/">
                    <Button
                      className="btn-primary"
                      style={{
                        width: '100%',
                        fontSize: 'calc(1.305rem + .66vw)',
                      }}
                    >
                      Home
                    </Button>
                  </Link>
                </Col>
                <Col xs={10} md={6} lg={5} className="order-1 order-md-2">
                  <img
                    src={fourOhFourImage}
                    className="img-fluid rounded mb-4 mb-md-0"
                    alt="Injured deer on crutches hobbling away from the road."
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default NotFoundPage
